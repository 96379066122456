<template>
  <div>
    
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>              
              <v-col md="8" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="descricao">
                  <v-text-field
                    v-model="convenioNovo.descricao"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoEditarNome"
                    label="Nome"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="4" sm="12" class="mt-2">
                <v-switch
                  v-model="convenioNovo.ativo"
                  class="mt-0 pt-0"
                  :readonly="readonly"
                  :disabled="$store.state.layout.loading"
                  :label="descricaoAtivo"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.convenios')
              "
              :disabled="salvarDisabled"
              :carregando="carregandoSalvar"
              @click="$emit('salvar', convenioNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
   
    readonly: {
      type: Boolean,
      default: false,
    },
    salvarDisabled: {
      type: Boolean,
      default: false,
    },
    carregandoEditarNome: {
      type: Boolean,
      default: false,
    },
    convenio: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      convenioNovo: {
        descricao: null,
        ativo: true,
      },
    };
  },
  computed: {
    descricaoAtivo() {
      return this.convenioNovo.ativo ? "Ativo" : "Inativo";
    },
  },
  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    convenio(convenio) {
      this.convenioNovo = convenio;
    },
  },
};
</script>
